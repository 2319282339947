import styled from 'styled-components';
import { Dialog as MUIDialog } from '@mui/material';

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px 25px 25px 25px;
  height: 100%;
`;

export const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--background-form);
  overflow-y: overlay;
  padding: 20px 40px 20px 20px;
  @supports (-moz-appearance: none) {
    overflow-y: scroll;
  }
`;

export const Grid = styled.div<{ groups: number }>`
  display: ${props => (props.groups > 1 ? 'grid' : 'block')};
  grid-template-columns: [col] 50% [col] 50%;
  grid-gap: 20px;
  @supports (-moz-appearance: none) {
    margin-bottom: 20px;
  }
`;

export const Cell = styled.div<{ takeAllHeightAvailable?: boolean; background?: boolean }>`
  padding: 16px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: ${props => (props.takeAllHeightAvailable ? 1 : 'none')};
  border: 1px solid #a7a7a7;
  background: ${props => (props.background ? 'var(--background-form)' : 'var(--bgInspectorFullscreenContent)')};
`;

export const CellHeader = styled.div`
  font-size: var(--headerFontSize);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--foreground-form-header);
  margin-bottom: 10px;
`;

export const Dialog = styled(MUIDialog)<{ display_type?: string; groupslength: number }>`
  height: calc(${props => (props.groupslength > 1 ? 85 : 70)}vh - 40px);
  margin: 40px auto;
  width: ${props => (props.groupslength > 1 ? 80 : 50)}vw;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;
