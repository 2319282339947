import { groupedDataColumn } from 'src/utils/constants';
import { useEffect, useRef } from 'react';

interface FooterSummary {
  e: any;
  targetCompareVal: number;
  checkEquality?: boolean;
}

export const randomStringKey = (length: number, chars: string) => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const highlightSummaryFooter = ({ e, targetCompareVal, checkEquality = false }: FooterSummary) => {
  const currentTotalValue = e.component.getTotalSummaryValue(e.column.caption);
  e.cellElement.classList.toggle(
    'highlight-in-red',
    checkEquality
      ? currentTotalValue !== targetCompareVal
      : currentTotalValue > targetCompareVal || currentTotalValue < 0 || !currentTotalValue,
  );
  e.cellElement.classList.toggle(
    'highlight-in-green',
    checkEquality
      ? currentTotalValue === targetCompareVal
      : currentTotalValue <= targetCompareVal && currentTotalValue >= 0 && currentTotalValue,
  );
};

export const footerSummaryFields = ({ column, e, formProps }: any) => {
  switch (column) {
    case 'Allocation %':
      highlightSummaryFooter({ e, targetCompareVal: 100, checkEquality: true });
      break;
    case 'Target Notional':
    case 'Expected Notional':
      highlightSummaryFooter({ e, targetCompareVal: formProps?.orderExpectedNotional });
      break;
    case 'Commitment':
      highlightSummaryFooter({ e, targetCompareVal: formProps?.orderCommitment });
      break;
    case 'Trade Value':
      highlightSummaryFooter({ e, targetCompareVal: formProps?.parent?.parent_trade_value });
      break;
  }
};

export const onColumnGrouped = (e: any) => {
  if (e.name !== 'columns' || !e.fullName.includes('groupIndex')) return;
  const groupedColumns = e.component
    .state()
    .columns?.filter((column: any) => column.groupIndex !== undefined)
    .map((column: any) => column.dataField);
  if (e.value !== undefined) {
    const columnOptions = e.component.columnOption(groupedDataColumn.dataField);
    // Check if a column is grouped
    if (!columnOptions) {
      e.component.addColumn(groupedDataColumn);
    }
  } else if (e.previousValue !== undefined) {
    //Delete column when grouping filter is removed
    if (groupedColumns.length === 1) {
      e.component.deleteColumn(groupedDataColumn.dataField);
      e.component.clearGrouping();
    }
  }
};

/**
 * Quickly see which prop changed
 * and caused a re-render by adding a single line to the component.
 *
 * USAGE:
 * function MyComponent(props) {
 *   useWhyDidYouUpdate('MyComponent', props)
 *
 *   return <div ... />
 * }
 *
 * OUTPUT:
 * [why-did-you-update] MyComponent { myProp: { from 'oldvalue', to: 'newvalue' } }
 *
 * SHARE:
 * This tip on Twitter: https://twitter.com/brunolemos/status/1090377532845801473
 * Also follow @brunolemos: https://twitter.com/brunolemos
 */
export const useWhyDidYouUpdate = (name: string, props: any) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps: any = useRef({ current: {} });

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changes: any = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changes
          changes[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changes).length) {
        console.info('[why-did-you-update]', name, changes);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};
