import { QueryConfig } from 'redux-query';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { RootState } from 'src/reducers/rootReducer';

// Make store view props mandatory for views requests
export interface GlideQueryStatic extends GlideQuery {
  storePropName?: string;
}

export interface GlideQueryView extends GlideQuery {
  storeViewName: string;
  storeViewProp: string;
}

// Register all glide endpoints here
export type GlideEndpoint =
  | '/glide/display-view/groups'
  | '/glide/gget'
  | '/glide/hypo/scenarios'
  | '/glide/object'
  | '/glide/order/update'
  | '/glide/order/workflow-transition'
  | '/glide/new'
  | '/glide/update'
  | '/glide/view'
  | '/gsearch'
  | '/gaudit'
  | '/gset'
  | `/glide/layout/share`
  | `/glide/object/delete`
  | `/glide/views/layouts`;

export interface GlideRequestBody {
  uri?: string;
  [key: string]: any;
}

export interface GlideQuery extends Partial<QueryConfig> {
  endpoint: GlideEndpoint;
  cache?: boolean;
  storeViewName?: string;
  storeViewProp?: string;
  storePropName?: string;
}

interface GetTransformParams {
  transformFn: any;
  storeViewName: string;
  storeViewProp?: string;
  storePropName?: string;
}

function getTransform({ transformFn, storeViewName, storeViewProp, storePropName }: GetTransformParams) {
  if (transformFn) return transformFn;

  if (storePropName) {
    return (response: any) => ({ [storePropName]: response });
  }

  // View transformations
  if (storeViewProp) {
    return (body: any) => ({
      views: {
        [storeViewName]: {
          [storeViewProp]: parseDisplayViewData(body),
        },
      },
    });
  }

  return (body: any) => ({
    views: {
      [storeViewName]: parseDisplayViewData(body),
    },
  });
}

interface GetUpdateParams {
  updateFn: any;
  storeViewName: string;
  storePropName?: string;
  storeViewProp?: string;
}

function getUpdate({ updateFn, storeViewName, storeViewProp, storePropName }: GetUpdateParams) {
  if (updateFn) return updateFn;

  // Static updates
  if (storePropName) {
    return {
      [storePropName]: (_: any, next: any) => next,
    };
  }

  // Store in view within a dedicated path (ie: inspector data)
  if (storeViewProp) {
    return {
      views: (prev: any, next: any) => ({
        ...prev,
        [storeViewName]: {
          ...prev[storeViewName],
          [storeViewProp]: next[storeViewName][storeViewProp],
        },
      }),
    };
  }

  // Store directly in view (ie: FETCH_CLIENT_VIEW)
  return {
    views: (prev: any = {}, next: any = {}) => ({
      ...prev,
      [storeViewName]: {
        ...prev[storeViewName],
        ...next[storeViewName],
      },
    }),
  };
}

export const glideQuery = ({
  endpoint,
  body,
  options = {},
  cache = false,
  queryKey,
  update = undefined,
  transform = undefined,
  storePropName,
  storeViewName,
  storeViewProp,
  meta = undefined,
}: GlideQuery): QueryConfig => {
  const _queryKey = queryKey || body?.uri || Object.keys(body)[0];
  const _storeViewName = storeViewName || _queryKey;
  return {
    url: endpoint,
    options: { method: 'GET', ...options },
    body,
    force: !cache,
    queryKey: _queryKey,
    update: getUpdate({ updateFn: update, storeViewName: _storeViewName, storeViewProp, storePropName }),
    transform: getTransform({ transformFn: transform, storeViewName: _storeViewName, storeViewProp, storePropName }),
    meta,
  };
};

export const glideQuerySelectorBottomPanel = (state: RootState, storeViewName?: string) => {
  if (!storeViewName) return null;
  const querySelectorData = glideQuerySelectorViewName(state, storeViewName);
  if (
    querySelectorData.hasOwnProperty('clientViewConfiguration') &&
    !querySelectorData?.clientViewConfiguration?.hasOwnProperty('uri')
  ) {
    return {
      ...querySelectorData,
      clientViewConfiguration: {
        ...querySelectorData?.clientViewConfiguration,
        uri: querySelectorData?.uri,
      },
    };
  }
  return querySelectorData;
};

export const selectGridSchema = (state: RootState) => {
  const cvcUri = state.tabs.activeViewUri;
  return state.entities.views[cvcUri]?.schema || [];
};

export const glideQuerySelectorViewName = (state: RootState, storeViewName?: string) => {
  if (!storeViewName) return null;
  return state.entities.views?.[storeViewName as string] || { data: [], schema: [] };
};

export const glideQuerySelector = (state: RootState, storeViewName?: string, storeViewProp?: string) => {
  if (!storeViewName) return null;
  return state.entities.views?.[storeViewName as string]?.[storeViewProp as string];
};

export const selectStatic = (state: RootState, storePropName: string) => {
  if (!storePropName) return null;
  return state.entities?.[storePropName];
};

export const isPendingQuerySelector = (state: RootState, uri: string) => {
  if (!uri) return null;
  return state.queries?.[uri]?.isPending;
};

export const selectGlideObjectLoading = (state: RootState) => {
  const cvcUri = state.tabs.activeViewUri;
  const currentObjectInView = state.components.viewComponents[cvcUri]?.currentObjectInView;
  if (!currentObjectInView?.uri) return null;
  const isObjectLoading = state.queries?.[currentObjectInView.uri]?.isPending;
  // console.info('isObjectLoading', isObjectLoading, currentObjectInView);
  return isObjectLoading;
};

export const selectGlobalGlideObjectLoading = (state: RootState) => {
  const currentObjectInView = state.components.global?.currentObjectInView;
  if (!currentObjectInView?.uri) return null;
  const isObjectLoading = state.queries?.[currentObjectInView.uri]?.isPending;
  return isObjectLoading;
};
