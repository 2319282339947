import React, { useEffect, useState } from 'react';
import * as S from './credit-detail-inspector.style';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';
import { RootState } from 'src/reducers';
import { selectGlideObjectLoading } from 'src/api/query';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import GlideInspectorFooter from 'src/components/inspectors/glide-inspector/glide-inspector-footer';
import { DisplayViewData, DisplayViewGroup, TabData, TabObject } from 'src/api/queries/display-view';
import { selectExpandedAction } from 'src/sagas/action/actions.saga';
import { GlideObject } from 'src/models/glide/glideObject';
import { CreditDetailInspectorProps, selectStaticComponent } from 'src/reducers/components';
import {
  closeCreditDetailForm,
  discardCreditDetailFormChanges,
  creditDetailForm,
  selectCreditDetailForm,
} from 'src/reducers/creditDetailForm.reducer';
import { CONFIRMATION_DIALOG, backDropClick } from 'src/utils/constants';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import TabPanel from 'devextreme-react/tab-panel';
import { GlideTimeSeries } from 'src/components/glide-time-series/glide-time-series';
import orderBy from 'lodash/orderBy';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { extractFieldsFromTabData } from 'src/mappers/common-mapper-functions';
import { flushSync } from 'react-dom';
import GlideDataContent from 'src/components/forms/glide-data-content/glide-data-content';

interface CreditDetailInspector {
  displayName: string;
  uri: string;
  displayViewData?: DisplayViewData;
  tab_data?: TabData;
}

interface ReduxProps {
  isGlideObjectLoading: boolean | null;
  creditDetailForm: creditDetailForm;
  creditDetailInspectorData: CreditDetailInspector;
  creditDetailInspector: CreditDetailInspectorProps;
  expandedAction?: GlideObject;
}

export interface CreditDetailInspectorReduxDispatchProps {
  discardCreditDetailFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean) => void;
  closeCreditDetailForm: (hideInspector: boolean) => void;
}

export const CREDIT_DETAIL_INSPECTOR = 'creditDetailInspector';

export interface TabGridData {
  data: { [key: string]: any }[];
  schema: { display_name: string }[];
}

const CreditDetailInspector = ({
  creditDetailForm,
  creditDetailInspectorData,
  creditDetailInspector,
  expandedAction,
  discardCreditDetailFormChanges,
  closeCreditDetailForm,
  isGlideObjectLoading,
}: ReduxProps & CreditDetailInspectorReduxDispatchProps) => {
  if (!creditDetailInspectorData || !creditDetailInspector.isVisible) return null;
  const [data, setData] = useState<any>(null);
  const [tabData, setTabData] = useState<TabObject[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [capitalStructureFields, setCapitalStructureFields] = useState<any[]>([]);
  const [selectedTabData, setSelectedTabData] = useState<TabGridData>({
    data: [],
    schema: [],
  });

  const closeCreditDetailInspectorDialog = () => {
    closeCreditDetailForm(true);
  };

  const {
    DialogComponent: QuitCreditDetailInspector,
    onDispatcherClick: displayConfirmationDialog,
  } = useConfirmationDialog({
    onClick: closeCreditDetailInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const onClose = (_event: any, reason?: any) => {
    if (reason === backDropClick) return;
    discardCreditDetailFormChanges(displayConfirmationDialog, true);
  };

  const onChangeField = (fieldChange: any, formGroupName: string, fieldRules?: any) => {
    dispatchActions.creditDetailForm.changeCreditDetailFormField(fieldChange, formGroupName, fieldRules);
  };

  useEffect(() => {
    if (creditDetailInspectorData) {
      // expandedAction is for global actions and for inspectorData, the actions are available in the displayViewData
      if (!creditDetailInspectorData.displayViewData?.actions && expandedAction)
        creditDetailInspectorData.displayViewData = expandedAction?.data;
      setData(creditDetailInspectorData);

      // get tabs data as sorted array based on ordering number from config
      const tab_data = orderBy(
        creditDetailInspectorData?.tab_data,
        (item: TabObject) => item.display_view.data.ordering,
        ['asc'],
      );
      setTabData(tab_data);
    }
  }, [creditDetailInspectorData, expandedAction]);

  useEffect(() => {
    if (tabData) {
      // set captial structure data and schema and fields
      const { fields, data: capitalStructureData, schema: capitalStructureSchemaArr } = extractFieldsFromTabData(
        tabData,
        selectedIndex,
      );
      setCapitalStructureFields(fields);
      setSelectedTabData({
        data: [...capitalStructureData],
        schema: [...capitalStructureSchemaArr],
      });
    }
  }, [tabData, selectedIndex]);

  if (!data) return null;
  const groupsLength = Object.keys(data.data).length;
  // @ts-ignore

  const itemTitleRender = (tab: TabObject) => {
    return <span>{tab.display_view.data?.display_name}</span>;
  };

  const TimeSeries = (
    <S.GridWrapper>
      <GlideTimeSeries
        tabUri={tabData?.[selectedIndex]?.display_view.uri as string}
        seriesData={selectedTabData}
        fields={capitalStructureFields}
        isPending={false}
        displayViewObj={{}}
        uri={creditDetailInspectorData?.uri}
      />
    </S.GridWrapper>
  );

  const itemComponent = () => TimeSeries;

  const onSelectionChange = (e: any) => {
    if (e.name == 'selectedIndex') {
      flushSync(() => {
        setSelectedIndex(e.value);
      });
    }
  };

  return (
    <>
      <QuitCreditDetailInspector />
      <S.Dialog open onClose={onClose} groupsLength={groupsLength} disableEnforceFocus={true} disableEscapeKeyDown>
        {isGlideObjectLoading ? (
          <Loading show={true} type={LoadingIconType.Glide} size={LoadingIconSizes.extraLarge} full />
        ) : (
          <>
            <PanelHeader
              showCloseBtn
              showBackButton={false}
              title={creditDetailInspectorData.displayName}
              isExpanded={true}
              tooltipText={creditDetailInspectorData.displayName}
              onClose={onClose}
            />
            <S.CreditDetailWrapper>
              <S.Grid data-testid="credit-detail-inspector-form" groups={groupsLength}>
                <S.GridContainer>
                  {Object.entries(data?.data).map(([formGroupName, value]) => {
                    const groupName = formGroupName.length > 1 ? formGroupName : '';
                    return (
                      <S.Cell takeAllHeightAvailable background key={formGroupName}>
                        <S.CellHeader>{groupName}</S.CellHeader>
                        <GlideDataContent
                          uri={creditDetailInspectorData.uri}
                          dataTestId={creditDetailInspectorData.uri}
                          optionalStyles={{ noPadding: true, noBackground: true }}
                          hideFooter
                          form={creditDetailForm as creditDetailForm}
                          useGOMGrid={false}
                          fieldRulesObject={{
                            formValues: creditDetailForm.formValues,
                            formFields: creditDetailForm.formFields,
                          }}
                          formName={formGroupName}
                          onChangeField={onChangeField}
                          formData={value}
                          isEditing={creditDetailForm.isEdit}
                        />
                      </S.Cell>
                    );
                  })}
                </S.GridContainer>
                {creditDetailInspectorData.displayViewData?.actions && (
                  <GlideInspectorFooter
                    actions={creditDetailInspectorData.displayViewData.actions}
                    workflowActions={creditDetailInspectorData.displayViewData?.workflow_transitions}
                    targetInstance={creditDetailInspectorData.displayViewData?.instance}
                    isExpanded
                    isCreditDetailInspector={creditDetailInspector.isVisible}
                  />
                )}
              </S.Grid>
              <S.TabPanel className="CreditDetail-TabPanel">
                <S.DXTabsClassesOverride>
                  <TabPanel
                    dataSource={tabData}
                    itemTitleRender={itemTitleRender}
                    itemRender={itemComponent}
                    selectedIndex={selectedIndex}
                    onOptionChanged={onSelectionChange}
                  />
                </S.DXTabsClassesOverride>
              </S.TabPanel>
            </S.CreditDetailWrapper>
          </>
        )}
      </S.Dialog>
    </>
  );
};
export const selectCreditDetailInspectorData = (state: RootState): DisplayViewGroup =>
  state.entities[CREDIT_DETAIL_INSPECTOR];

const mapStateToProps = (state: RootState): ReduxProps => ({
  expandedAction: selectExpandedAction(state),
  creditDetailForm: selectCreditDetailForm(state),
  isGlideObjectLoading: selectGlideObjectLoading(state),
  creditDetailInspectorData: selectCreditDetailInspectorData(state),
  creditDetailInspector: selectStaticComponent(CREDIT_DETAIL_INSPECTOR)(state),
});

const mapDispatchToProps = (dispatch: any): CreditDetailInspectorReduxDispatchProps => ({
  discardCreditDetailFormChanges: (displayConfirmationDialog: Function, hideInspector: boolean) =>
    dispatch(discardCreditDetailFormChanges(displayConfirmationDialog, hideInspector)),
  closeCreditDetailForm: (hideInspector: boolean) => dispatch(closeCreditDetailForm(hideInspector)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditDetailInspector);
