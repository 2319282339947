import { Checkbox, InputBase, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Warning } from '@mui/icons-material';
import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

export const StyledToggleButton = styled(BasicButton)<{ backgroundcolor?: string; width?: string }>`
  background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  border-radius: 2px;
  margin-right: 1px;
  border: none;
  min-width: 0;
  &:hover {
    background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  }
  &:disabled {
    background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  }
  width: 46px;
  height: 28px;
  text-transform: capitalize;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4px;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 2px;
  border-bottom: var(--foreground-form-row-border);
  padding-left: 10px;
  padding-right: 10px;
`;

const BaseInputStyles = `
  flex: 1;
  border-radius: 2px;
  border: none;
  color: var(--foreground-form);
  font-size: var(--form-input-font-size);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;

`;

export const Error = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

export const StyledWarningIcon = styled(Warning)`
  color: var(--foreground-error);
  font-size: var(--error-icon-font-size);
`;

export const Input = styled(InputBase)<{
  multiline?: boolean;
  error?: boolean;
  editIsEnabled?: boolean;
  hasFocus?: boolean;
}>`
  ${BaseInputStyles}
  height: ${props => {
    if (props.multiline) return '70px';
    if (props.editIsEnabled) return '24px';
    return '28px';
  }};
  border-radius: 3px;
  align-items: ${props => (props.multiline ? 'flex-start' : 'center')};
  overflow: ${props => (props.multiline ? 'auto' : 'hidden')};
  line-height: 16px;
  border: ${props => {
    if (props.error) return '2px solid var(--foreground-error)';
    if (props.editIsEnabled && !props.hasFocus) return 'var(--foreground-form-input-edit-border)';
    if (props.editIsEnabled && props.hasFocus) return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};
  font-weight: ${props => (props.editIsEnabled ? 'normal' : 'inherit')};
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::placeholder {
    color: red !important;
  }
`;

export const DisabledField = styled.div`
  ${BaseInputStyles}
  background-color: transparent;
  color: var(--subtitle);
  cursor: not-allowed;
  height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`;

export const StyledCheckbox = styled(Checkbox)<{ checkBoxChecked?: boolean }>`
  ${BaseInputStyles}
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  flex: 0;
  padding: 0;
  border-color: ${props =>
    props.checkBoxChecked ? 'var(--background-form-checkbox-checked)' : 'var(--background-form-checkbox-disabled)'};
`;

export const StyledLabel = styled.label<{ width?: string }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
  width: ${props => props.width || '150px'};
  color: var(--foreground-form);
  font-weight: bold;
  font-size: var(--form-label-font-size) !important;
`;

export const StyledInput = withStyles(Input, {
  disabled: {
    color: 'var(--foreground-form)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    lineHeight: '10px',
    backgroundColor: 'transparent !important',
    fontWeight: 'normal',
    WebkitTextFillColor: 'unset !important',
  },
  input: {
    padding: '5px',
    // width: (props: any) => props?.width,
    fontSize: 'var(--form-input-font-size)',
  },
});

export const MenuItemStyled = withStyles(MenuItem, {
  root: {
    height: 36,
    width: 210,
    margin: 0,
    padding: 2,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.14,
    letterSpacing: -0.2,
    color: 'var(--foreground-form)',
    backgroundColor: 'var(--dropdownBackground)',
    '&:hover': {
      backgroundColor: 'var(--dropdownHoveredLabel)',
    },
  },
});

export const MenuItemTextStyled = styled.div`
  display: flex;
  padding: 6px 0 6px 10px;
  width: 100%;
  align-items: center;
  font-size: var(--menuItemFontSize);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: var(--foreground-form);
`;

export const StyledRequiredStar = styled.span`
  color: var(--foreground-form-required-field);
  position: relative;
  top: 4px;
  padding-left: 5px;
`;

export const ModalButton = styled.button<{ editIsEnabled?: boolean }>`
  ${BaseInputStyles}
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  padding-left: ${props => (props.editIsEnabled ? '0px' : '5px')};
`;

export const StyledDoneIcon = styled(CheckIcon)`
  color: var(--forground-form-done-icon);
  font-size: var(--done-icon-font-size);
  margin-right: 2px;
  position: relative;
  bottom: 2px;
`;

export const StyledTogglerDiv = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding-left: ${props => (props.disabled ? '5px' : '0px')};
`;
