import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import styled from 'styled-components';

const StyledSvgPath = styled.path<{ disabled?: boolean; hasFocus?: boolean; checked: boolean }>`
  outline: ${props => {
    if (props.disabled) return 'var(--foreground-form-checkbox-disable-border)';
    if (props.checked && !props.hasFocus) return 'var(--foreground-form-checkbox-checked-border)';
    if (!props.checked && !props.hasFocus) return 'var(--foreground-form-checkbox-unchecked-border)';
    if (props.hasFocus) return 'var(--foreground-form-input-focus-border)';
    return 'none';
  }};

  fill: ${props => {
    if (props.checked && props.disabled) return 'var(--background-form-checkbox-disabled)';
    if (!props.checked && props.disabled) return 'none';
    if (props.checked && !props.disabled) return 'var(--background-form-checkbox-checked)';
    if (!props.checked && !props.disabled && props.hasFocus) return 'var(--foreground-form-checkbox-unchecked-border)';
    return 'none';
  }};
  border-radius: 1px;
`;

export const CheckboxFilledIcon: React.SFC<SvgIconProps & { disabled?: boolean; hasFocus?: boolean }> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <StyledSvgPath
        disabled={props.disabled}
        hasFocus={props.hasFocus}
        checked={true}
        // fill={props.disabled ? 'var(--background-form-checkbox-disabled)' : 'var(--background-form-checkbox-checked)'}
        d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"
      />
      <path
        fill={props.disabled ? 'var(--foreground-form-checkbox-disabled-icon)' : 'var(--foreground-form)'}
        d="M10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"
      />
    </g>
  </SvgIcon>
);

export const CheckboxIntermediate: React.SFC<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <path id="a" d="M0 0h24v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="var(--text)">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="var(--blue)"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        mask="url(#b)"
      />
      <path fill="var(--text)" d="M17 13H7v-2h10z" mask="url(#b)" />
    </g>
  </SvgIcon>
);

export const CheckboxUncheckedIcon: React.SFC<SvgIconProps & { disabled?: boolean; hasFocus?: boolean }> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <StyledSvgPath
        disabled={props.disabled}
        hasFocus={props.hasFocus}
        checked={false}
        // fill={props.disabled ? 'var(--background-form-checkbox-disabled)' : 'var(--foreground-form)'}
        d="M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </g>
  </SvgIcon>
);
